<template>
    <custom-table
        :title="$t('pages.developer.videoManagement.migrate.title')"
        :subTitle="$t('pages.developer.videoManagement.migrate.subTitle')"
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        changeUrlPage
        @action="handleClickAction"
        @changeTable="handleTableChange">
        <template v-slot:progress="{ row: record }">
            <el-progress :text-inside="true" :stroke-width="20" :percentage="record.progress_percentage"/>
        </template>
        <template v-slot:createdAt="{ row: record }">
            {{ $moment(record.created_at).format("DD.MM.YYYY - HH:mm:ss") }}
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <router-link :to="sprintf('/developer/video-management/migrate/%d/detail', [record.id])" target="_blank" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/general/gen004.svg" />
                    </span>
                </router-link>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_migrate" ref="migrateModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_add_customer_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="migrateForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.developer.videoManagement.migrate.cols.host') }}</label>
                                <el-form-item prop="host" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.host" type="text"/>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.developer.videoManagement.migrate.cols.token') }}</label>
                                <el-form-item prop="credentials.bearer_token" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.credentials.bearer_token" type="textarea" rows="3"/>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.developer.videoManagement.migrate.cols.moveManager') }}</label>
                                <el-form-item prop="migrate_admins" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.data.migrate_admins" :placeholder="$t('common.chooseSelect')" class="w-100" multiple>
                                        <el-option v-for="(admin, adminIndex) in admins" :key="adminIndex" :value="admin.id" :label="sprintf('%s (%s)', [admin.full_name, admin.email])"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.developer.videoManagement.migrate.cols.limit') }}</label>
                                <el-form-item prop="migrate_file_limit" :rules="$validation.getMessage(['required'])">
                                    <el-input-number v-model="form.data.migrate_file_limit" :min="1"/>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{  $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "index",
    components: {
        CustomTable
    },
    data(){
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("common.total"),
                    key: "items_count"
                },
                {
                    name: this.$t("common.inProcess"),
                    key: "downloading_items_count"
                },
                {
                    name: this.$t("common.pending"),
                    key: "pending_items_count"
                },
                {
                    name: this.$t("common.completed"),
                    key: "downloaded_items_count"
                },
                {
                    name: this.$t("common.progress"),
                    scopedSlots: {customRender: "progress"}
                },
                {
                    name: this.$t("common.createdAt"),
                    scopedSlots: {customRender: "createdAt"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                data: {
                    credentials: {},
                    migrate_admins:[]
                }
            }
        }
    },
    computed: {
        table() {
            return this.$store.state.developer.videoManagement.migrator.table;
        },
        admins() {
            return this.$store.state.developer.customer.table.data;
        },
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t("menu.developerManagement.videoManagement.migrate"), [this.$t("menu.developerManagement.title"), this.$t("menu.developerManagement.videoManagement.title")]);
        this.$store.dispatch('developer/videoManagement/migrator/get', {
            page: {},
            filterData: this.$root.getFilterWithUrl()
        });
        this.$store.dispatch('developer/customer/get', {
            page: { pageSize: 9999 }
        });
    },
    methods:{
        newRecord(){
            this.form.updateStatus = false;
            this.form.data = {
                host: "http://localhost:94",
                credentials: {
                    bearer_token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZWUzZmJiMjJiNjQyODZjMDc2MWJiNDRkZDljMTI3N2Q5ZDBlNGVlNDI2NzU1NGViNzZhMmY2MzA0ZTY0N2FjZWE2YmRiZTJkYjEzMDA0NGQiLCJpYXQiOjE2NjAxMzg1ODguOTUwNTMzLCJuYmYiOjE2NjAxMzg1ODguOTUwNTM0LCJleHAiOjE2ITE2NzQ1ODguOTQ1NzM1LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.cgSoOLWHJGGocL_BKDc-aBocCZGYiOwVdeowALzNYpV_eodGEDY6Tq_xSs63byX6EIc6XtND_1rKp505jgC1nf2Wv4-M1sx8VczYWTZTjRfl4yQwjGwROCPA_ACppDe52uwiJ158UxqM3DG67aFrsB_mnUktWgcyVh-D0SIp98gkcRdun0mIW5u84BXj9lVEWGsIG5PPAVLypE3Wq5GU_cgErPC_0xbXsY5_IaRkpRWcCzcOtzlOUye7IzNImAPLAY3ZZGU-TIE5tRHhfQoldxKTK3OG0IrJ-pWFhrkSpq5dZVFzMmoWGahDz2hiKgqZmAUrQMuSotAc5Cgv7H2fyiyR2Az7VSEEK2COuDJ1e7MVrw245C-cQtDJkHYusOsP-0Lu6RzN02IQpZNDsKrx62n4H6MkYXIUGiEgQjPpQaVl3MVujuevKV-NxELDqevFTncnGqiAJEQD3lABEyJZO1MzSen6UeQIshY0dhNNIkIaKimSZK_izivxP7XPCa13VAZKr_huvy2Y73rviBy4T5VX_HibS3ZTZuhOkK-sPmw7sKKJfQg7RwmMIq5z8ix5MoXGiuM0CrYdwOCj8lrBEOlYU3XNTuF_n8XuFC900_T98tqH4cHupSBRzks49vOAfqSDShyp04KR-gzdoP9qArUmMLltlfFaueoKSySZ48I"
                },
                migrate_admins: [],
                migrate_file_limit: 1
            };
            this.form.title = this.$t("pages.developer.videoManagement.migrate.new");
            this.showModal(this.$refs.migrateModal);
        },
        onSubmit(){
            this.$refs.migrateForm.validate((valid) => {
                if(valid) {
                    this.form.loading = true;

                    this.axios.post(this.endpoint.get('video_management_migrator', 'developer'), this.form.data).then(response => {
                        this.onResponse(response.data, () => {
                            this.refreshTable();
                            this.hideModal(this.$refs.migrateModal);
                        }, () => {
                            this.form.loading = false;
                        });
                    }).catch(error => {
                        error.response.data.message = this.$t('pages.developer.videoManagement.migrate.responseMessages.' + error.response.data.message, error.response.data.message);

                        this.onResponseFailure(error.response.data, () => {
                            this.form.loading = false;
                        });
                    });
                } else {
                    return false;
                }
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.newRecord();
                    break;

                default:
                    break;
            }
        },
        refreshTable(){
            this.$store.dispatch("developer/videoManagement/migrator/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("developer/videoManagement/migrator/get", {
                page: pagination,
                filterData: filterData
            });
        },
    }
}
</script>

<style>
</style>